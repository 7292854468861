import React from 'react'
import { NarrowContainer } from 'src/features/layout'

const AboutPageBody = () => (
  <NarrowContainer>
    <h1>Theology through story</h1>
    <p>
      For thousands of years the world waited for the coming of the Son of God,
      the Word of God made flesh. In His beautiful face, we could see God for
      the first time since Adam and Eve were cast out of Eden’s borders.
    </p>
    <p>
      Before the time of the Messiah, the Almighty sent His words and works
      before Him testifying of His greatness and grandeur with a hope-filled
      promise. In time, and in a world being made new, we would be able to see
      past our sins and walk with Him again in a garden better than the first.
    </p>
    <p>
      In Bethlehem, the second creation was manifest as God brought forth the
      Light of the World in the form of the baby Jesus. The story of His life;
      the way He walked among us, loving, healing and forgiving, was the first
      walk of this new Eden that led him to Gethsemane and Golgotha. In the
      shame and humility of His death, he led us, who were dead in our
      trespasses and sins, to understand how we too can arise to walk in newness
      of life.
    </p>
    <p>
      In the lives of His disciples, those who He said were now the light of the
      world, His light still illuminates the darkness of a world once
      overshadowed by a constant storm of doom.
    </p>
    <p>
      <b>Foundations of Grace Publishing</b> is dedicated to showing forth these
      glorious lights in the life stories of those who have learned to follow
      their savior from His cross and to His glory.
    </p>
    <p>
      In these lives, these epistles who lived and breathed the manifestation of
      God’s glorious church, the great gleaming city on a hill that cannot be
      hid, together we will curse the darkness. Through these vessels of clay,
      broken like the lanterns of Gideon’s three hundred men, we seek to send
      the enemies of God to flight.
    </p>
    <p>
      To that end, we are dedicated to publishing untold stories or
      rediscovering ones that have been forgotten and holding them up as a great
      cloud of witnesses to encourage lives of holiness and determination,
      looking unto the one author, who is telling the grandest story of all.
    </p>
  </NarrowContainer>
)

export default AboutPageBody
