import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from '../../../components/seo'
import AboutPageBody from './AboutPageBody'

const GatsbyAboutPage = () => (
  <StandardPage>
    <SEO title="About" />
    <AboutPageBody />
  </StandardPage>
)

export default GatsbyAboutPage
